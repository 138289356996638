import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container } from 'react-grid-system';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/about-hero.jpg';
import TwoCol from '../components/rows/twocol/index';
import Follow from '../components/rows/cta/follow';
import Cta from '../components/rows/cta/index';
import Column from '../components/elements/column';
import Button from '../components/elements/button';

import EmailHeart from '../icons/email.svg';
import FaxHeart from '../icons/fax-heart.svg';
import PencilHeart from '../icons/pencil-heart.svg';

export default function About({ data }) {
  return (
    <Layout>
      <Seo
        title="About"
        description="Find out about PACE Cardiology & the affiliations that help us provide the very best in diagnostic results and 
          individualized care with the goal to improve heart health for everyone."
      />
      <Hero title="About" banner={Banner} />
      {data.allStrapiAbout.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <TwoCol
            title={node.about1.title}
            description={node.about1.description}
            image={node.about1.image.localFile.publicURL}
          />
          <div className="bg-gray-100 py-52 my-52 medical-bg">
            <h2 className="text-center mb-20">
              <span className="text-red-300">The</span> PACE Pledge
            </h2>
            <Container className="flex flex-wrap justify-between">
              <Column
                icon={<EmailHeart />}
                title={node.about2.col1_title}
                description={node.about2.col1_description}
                isCard
              />
              <Column
                icon={<FaxHeart />}
                title={node.about2.col2_title}
                description={node.about2.col2_description}
                isCard
              />
              <Column
                icon={<PencilHeart />}
                title={node.about2.col3_title}
                description={node.about2.col3_description}
                isCard
              />
              <div className="w-full mt-20 text-center">
                <Button
                  ghost
                  link="See a full list of our services"
                  href="/services"
                />
              </div>
            </Container>
          </div>
          <TwoCol
            reverse
            title={node.about3.title}
            description={node.about3.description}
            image={node.about3.image.localFile.publicURL}
          />
        </React.Fragment>
      ))}
      <Cta
        title="How to Book with <br> PACE"
        button="Learn More"
        href="/how-to-book"
      />
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query About {
    allStrapiAbout {
      edges {
        node {
          id # id of the node
          about1 {
            id
            title
            description
            image {
              localFile {
                publicURL
              }
            }
          }
          about2 {
            col1_title
            col2_title
            col3_title
            col1_description
            col2_description
            col3_description
            id
          }
          about3 {
            id
            title
            description
            image {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
